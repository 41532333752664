import './App.css';
import Typewriter from 'typewriter-effect';
import { useState } from 'react';
import Confetti from 'react-confetti';

const IMG_ARRAY = [
  'IMG_1211.jpeg',
  'IMG_1805.jpeg',
  'IMG_1983.jpeg',
  'IMG_5034.jpeg',
  'IMG_5284.jpeg',
  'IMG_7124.jpeg',
  'IMG_7975.jpeg',
  'IMG_2702.JPG',
  'IMG_2893.JPG',
  'IMG_3247.JPG',
  'IMG_1627.JPG',
  'IMG_2880.JPG',
  'IMG_7089.JPG',
  'IMG_0165.jpeg',
];

function App() {
  const [typewriterDone, setTypewriterDone] = useState(false);

  const [noCounter, setNoCounter] = useState(0);

  const [sheSaidYes, setSheSaidYes] = useState(false);

  const [audioReady, setAudioReady] = useState(false);

  const handlePlayAudio = () => {
    setAudioReady(true);
  };

  return (
    <div className="background">
      <div className="relative flex flex-col justify-center items-center w-full h-full gap-12 p-4">
        <div className="absolute w-screen h-screen flex">
          <div>
            {IMG_ARRAY.map((img, idx) => (
              <img src={img} alt={`picture_${idx}`} className={`h-[200px]}`} />
            ))}
          </div>
        </div>
        <div className="z-50">
          {audioReady ? (
            <>
              <div className="absolute top-0">
                <img src="kiss-gif.gif" />
              </div>
              <audio
                controls
                autoPlay
                className="absolute top-0 w-[148px] h-[64px] bg-white"
              >
                <source
                  src={`${process.env.PUBLIC_URL}/goodnightgooddream.mp3`}
                  type="audio/mp3"
                />
                Your browser does not support the audio element.
              </audio>
            </>
          ) : (
            <button
              className="absolute top-0 w-[148px] h-[64px] bg-white"
              onClick={handlePlayAudio}
            >
              PRESS ME FIRST
            </button>
          )}
        </div>
        <h1 className="fixed text-5xl font-bold text-white z-50 top-[30%]">
          4 APRIL
        </h1>
        <div className="backdrop-blur fixed text-white p-6">
          {!typewriterDone && (
            <>
              <div className="text-xl font-extrabold">
                <Typewriter
                  className="text-lg font-bold"
                  deleteSpeed={10}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('To my bb girl, April Valerie Huang')
                      .pauseFor(1500)
                      .deleteAll()
                      .typeString('This is our third Valentines!')
                      .deleteAll()
                      .deleteAll()
                      .typeString(
                        `I've been having the best time with you. From all the countries we visited, the random moments where we die of laughter, and the core memories we've shared with each other... I'm blessed to call you my best friend and lover.`
                      )
                      .changeDeleteSpeed(1000)
                      .deleteAll()
                      .typeString('Thank you for being you!')
                      .pauseFor(500)
                      .deleteAll()
                      .typeString(
                        `I know I'm tough on you... and I'm sorry for that.`
                      )
                      .pauseFor(250)
                      .deleteAll()
                      .typeString(
                        `I'm slowly figuring out my bad habits and I'm trying my best to work on them every single day. I'm grateful that I have you by myself while I figure it out.`
                      )
                      .deleteAll()
                      .typeString(
                        `You mean so much to me — I can't wait for the life we build together.`
                      )
                      .deleteAll()
                      .typeString(
                        `I'm going to do my very best and work my ass off to build a future where you and I can live our best lives.`
                      )
                      .deleteAll()
                      .typeString(
                        `I love you. I miss you. I can't wait to spend the next upcoming weeks together.`
                      )
                      .callFunction(() => setTypewriterDone(true))
                      .start();
                  }}
                />
                {typewriterDone && (
                  <span className-="z-50 text-white">I LOVE YOU. </span>
                )}
              </div>
            </>
          )}
          {typewriterDone && !sheSaidYes && (
            <div className="flex flex-col gap-4 text-lg font-extrabold w-full">
              <span>Will you be my Valentine in 2024 and 4eva??</span>
              <div className="flex gap-4 w-full justify-center">
                <button
                  className={`bg-[#5cb85c] w-1/2 p-2 rounded-lg h-[${
                    noCounter + 1
                  }rem] z-60`}
                  onClick={() => setSheSaidYes(true)}
                >
                  Yes
                </button>
                <button
                  className={`w-[${
                    500 / noCounter
                  }px] bg-[#FC100D] p-2 rounded-lg z-60`}
                  onClick={() => {
                    console.log('here');
                    setNoCounter(noCounter + 1);
                  }}
                >
                  No I'm trippin
                </button>
              </div>
            </div>
          )}

          {sheSaidYes && (
            <div className="w-screen h-screen flex">
              <Confetti recycle={true} />
              <div className="flex flex-col gap-4 self-center text-xl font-bold p-8">
                <img src="cute-gif.gif" />
                <span>YAY!! I love you baby! See you soon.</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
